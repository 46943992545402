import { State, Getters } from "store/data/task_detail_modal/state";
import * as deepmerge from 'deepmerge';

// Actions
import { loadTaskDetail } from "store/data/task_detail_modal/actions/load_task_detail";
import { taskUpdate } from "store/data/task_detail_modal/actions/task_update";
import { loadTaskTimeLog } from "store/data/task_detail_modal/actions/load_task_timelog";
import { loadTaskHistory } from "store/data/task_detail_modal/actions/load_task_history";
import { loadTaskComments } from "store/data/task_detail_modal/actions/load_task_comments";
import { markIncomplete } from "store/data/task_detail_modal/actions/mark_incomplete";
import { updateTaskFollowers } from "store/data/task_detail_modal/actions/update_task_followers";
import { updateTaskAttachments } from "store/data/task_detail_modal/actions/update_task_attachments";
import { markComplete } from "store/data/task_detail_modal/actions/mark_complete";
import { requestDeleteTask } from "store/data/tasks/actions/request_delete_task";
import { requestArchiveTask } from "store/data/tasks/actions/request_archive_task";
import { requestCancelTask } from "store/data/tasks/actions/request_cancel_task";

// Mutations
import { updateTaskObject } from "store/data/task_detail_modal/mutations/update_task_object";
import { updateModalTitle } from "store/data/task_detail_modal/mutations/update_modal_title";
import { updateTaskHistory } from "store/data/task_detail_modal/mutations/update_task_history";
import { updateTaskComments } from "store/data/task_detail_modal/mutations/update_task_comments";
import { setTaskUpdateBit } from "store/data/task_detail_modal/mutations/set_task_update_bit";
import { deleteTaskTimeLog } from "store/data/task_detail_modal/actions/delete_task_timelog";
import { updateTaskTimeLog } from "store/data/task_detail_modal/mutations/update_task_timelog";
import { updateFollowers } from "store/data/task_detail_modal/mutations/update_followers";
import { updateAttachments } from "store/data/task_detail_modal/mutations/update_attachments";
import { updateCommentsCount } from "store/data/task_detail_modal/mutations/update_comments_count";
import { updateLockVersion } from "store/data/task_detail_modal/mutations/update_lock_version";

const Actions = deepmerge.all([
  updateTaskAttachments,
  loadTaskDetail,
  taskUpdate,
  loadTaskTimeLog,
  loadTaskHistory,
  loadTaskComments,
  markIncomplete,
  updateTaskFollowers,
  markComplete,
  deleteTaskTimeLog,
  requestCancelTask,
  requestArchiveTask,
  requestDeleteTask
]);

const Mutations = deepmerge.all([
  updateCommentsCount,
  updateLockVersion,
  updateAttachments,
  updateTaskObject,
  updateModalTitle,
  updateTaskHistory,
  updateFollowers,
  updateTaskComments,
  setTaskUpdateBit,
  updateTaskTimeLog
]);

import { createHelpers } from 'vuex-map-fields';

const { updateTask } = createHelpers({
  getterType: 'getTask',
  mutationType: 'updateTask'
});

Mutations['updateTask'] = updateTask;

export const TaskDetailModalWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
