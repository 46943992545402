import { UPDATE_TASK_QUERY } from 'queries/tasks/update_task.js';
import { formatErrors } from  'concerns/format_errors.js';

export const taskUpdate = {
  taskUpdate: function(context, args) {
    return new Promise((res, rej) => {
      var input = {
        id: context.state.task.id,
        title: context.state.task.title,
        description: context.state.task.description,
        virtual_due_date: context.state.task.virtual_due_date,
        due_date: context.state.task.virtual_due_date,
        lock_version: parseInt(context.state.task.lock_version),
        virtual_due_time: context.state.task.virtual_due_time,
        assignee_id: args.assignee_id,
        attachment_ids: context.state.task.attachment_ids,
        task_follower_ids: context.state.task.task_follower_ids
      };

      args.apollo.mutate({
        mutation: UPDATE_TASK_QUERY,
        variables: {
          input: input
        }
      }).then((data) => {

        var task = data.data.updateTask.task;

        if (task.errors.length) {
          task.errors = formatErrors(task.errors);
          if(task.errors.base && task.errors.base[0].length) {
            var alertMessage = task.errors.base[0];
            args.showAlert('alert-error', alertMessage);
          }
        } else {
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['archived_tasks_list', 'tasks_list'] } }));
          res();
        }

        context.commit('updateTaskObject', task);
        args.componentObject.ajaxRequestSent = false;
        window.pubnubReady = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        rej();
      });
    });
  }
};
