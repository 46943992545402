import gql from 'graphql-tag';

export const FETCH_ACCOUNT = gql`
  query fetch_account($id: String!) {
    fetch_account(id: $id) {
      id
      name
      is_target
    }
  }
`;
