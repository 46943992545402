import { createHelpers } from 'vuex-map-fields';
import { defaultMomentTime } from 'concerns/default_moment_time.js';


const { getTimeLog } = createHelpers({
  getterType: 'getTimeLog'
});

export const State = {
  time_log_object: {
    duration: '',
    description: '',
    loggable_date: defaultMomentTime(),
    mark_complete: true,
    id: null,
    errors: {}
  },
  ajaxRequestSent: false,
  timeLogCreateSuccessful: false,
  timeLogUpdateSuccessful: false,
  taskMarkCompleteCancel: false,
  displayTimeLogModal: false,
  displayMarkCompleteModal: false,
  currentLoggable: {},
  show_toggle_button: true,
  mark_complete_toggable: true,
  mark_complete: '',
  errors: {}
};

export const Getters = {
  getTimeLog
};
