import { State, Getters } from "store/data/comments/state";
import * as deepmerge from 'deepmerge';

import { requestCreateComment } from "store/data/comments/actions/request_create_comment";
import { requestDeleteComment } from "store/data/comments/actions/request_delete_comment";
import { requestUpdateComment } from "store/data/comments/actions/request_update_comment";
import { updateCommentObject } from "store/data/comments/mutations/update_comment_object";
import { updateEditCommentObject } from "store/data/comments/mutations/update_edit_comment_object";
import { deleteCommentObject } from "store/data/comments/mutations/delete_comment_object";

const Actions = deepmerge.all([requestCreateComment, requestDeleteComment, requestUpdateComment]);
const Mutations = deepmerge.all([updateCommentObject, updateEditCommentObject, deleteCommentObject]);

import { createHelpers } from 'vuex-map-fields';

const { createComment } = createHelpers({
  getterType: 'getComment',
  mutationType: 'createComment'
});


Mutations['createComment'] = createComment;

export const AddCommentWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
