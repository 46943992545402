import gql from 'graphql-tag';

export const FETCH_SUB_WORK_ORDER_QUICK_VIEW_DATA = gql `
  query showSubWorkOrderQuery($id: String!) {
    sub_work_order(id: $id) {
      id
      db_id
      details
      edit_path
      is_archived
      title
      state
      state_name
      task_count_path
      is_work_order
      creator_id
      assignee_id
      not_accepted
      formatted_description
      accept_by_date_passed
      updated_at
      due_datetime
      accept_by_datetime
      total_time_logged_words
      sub_work_order_follower_ids
      survey_link
      estimated_work_time_logged_words
      wo_is_internal
      work_order {
        id
        title
      }
      account {
        id
        name
        profile_picture
      }
      contact {
        id
        name
        profile_picture
      }
    }
  }
`;
