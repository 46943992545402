import { createHelpers } from 'vuex-map-fields';

const { getComment } = createHelpers({
  getterType: 'getComment'
});

export const State = {
  comments_list: [],
  ajaxRequestSent: false,
  reset_comment: false,
  edit_comment: false,
  edit_comment_object: {}
};

export const Getters = {
  getComment
};
