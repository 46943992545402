import { CREATE_TASK_QUERY } from 'queries/tasks/create_task.js';
import { formatErrors } from  'concerns/format_errors.js';

export const requestCreateTask = {
  requestCreateTask: function(context, args) {
    var input = {
      title: context.state.current_task_object.title,
      description: context.state.current_task_object.description,
      virtual_due_date: context.state.current_task_object.virtual_due_date,
      virtual_due_time: context.state.current_task_object.virtual_due_time,
      assignee_id: args.assignee_id,
      attachment_ids: context.state.current_task_object.attachment_ids,
      task_follower_ids: context.state.current_task_object.task_follower_ids,
      task_category_id: args.category_id,
      taskable_type: args.taskable_type,
      taskable_id: args.taskable_id,
      redirect_tab: args.redirect_tab,
      redirect_path: args.redirect_path
    };

    args.apollo.mutate({
      mutation: CREATE_TASK_QUERY,
      variables: {
        input: input
      }
    }).then((data) => {

      var task = data.data.createTask.task;

      if (task.errors.length) {
        task.errors = formatErrors(task.errors);
        context.commit('updateTaskObject', task);
      } else {
        var alertMessage = data.data.createTask.success;
        args.componentObject.hideTaskFormModal();
        args.showAlert('alert-success', alertMessage);
        context.state.taskCreateSuccessful = true;
        window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['tasks_list'] } }));
        window.dispatchEvent(new CustomEvent('newTaskCreated'));
      }

      args.componentObject.ajaxRequestSent = false;
    }).catch((reason) => {
      args.showAlert('alert-error', reason.graphQLErrors[0].message)
      args.componentObject.ajaxRequestSent = false;
    });
  }
};
