import { createWorkOrder } from 'store/data/work_orders/create/actions/create_work_order'

import { State, Getters } from "store/data/work_orders/create/state";

import * as deepmerge from 'deepmerge';


import { createHelpers } from 'vuex-map-fields';

const { createNewWorkOrder } = createHelpers({
  mutationType: 'createNewWorkOrder'
});

const Actions = deepmerge.all([createWorkOrder]);
const Mutations = deepmerge.all([]);

Mutations['createNewWorkOrder'] = createNewWorkOrder;

export const QuickAddWorkOrderWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
