import { CANCEL_SUB_WORK_ORDER } from 'queries/work_orders/sub_work_orders/cancel_sub_work_order.js';

export const cancelSubWorkOrder = {
  cancelSubWorkOrder: function(_, args) {
    const input = {
      sub_work_order_id: args.subWorkOrderId,
      filters: window.location.search
    };
    return new Promise(res => {
      args.apollo.mutate({
        mutation: CANCEL_SUB_WORK_ORDER,
        variables: { input }
      }).then(response => {
        var result = response.data.cancelSubWorkorder;
        if (result.status == gon.graphql_states.success) {
          args.showAlert('alert-success', result.message)
          res()
        } else {
          args.showAlert('alert-error', result.message);
        }
        $('div[data-board-update-url="/admin/work_orders/board_updates"]').data('last-activity-hash', result.last_activity_hash);
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
      });
    });
  }
};
