import { createHelpers } from 'vuex-map-fields';

const { getTask } = createHelpers({
  getterType: 'getTask'
});

export const State = {
  current_task_object: {
    title: '',
    assignee: '',
    virtual_due_date: '',
    virtual_due_time: '11:59 pm',
    task_follower_ids: [],
    category_id: '',
    attachment_ids: [],
    description: '',
    errors: {},
    attachables_attachments_attributes: [{
      _destroy: false,
      id: null,
      attachment_id: null,
      attachment_attributes: {
        id: null,
        errors: { }
      }
    }],
  },
  selectedTaskCategory: '',
  assignee: '',
  showTaskModal: false,
  ajaxRequestSent: false,
  taskCreateSuccessful: false,
  selectedAssignee: '',
  attachment_type: 'TaskAttachment',
  association_name: 'attachments',
  attachment_label_name: 'Attachments',
  tasks: [],
  errors: {}
};

 export const Getters = {
  getTask
};
