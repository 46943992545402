import { FETCH_CONTACT } from 'queries/opportunities/fetch_contact.js';

export const fetchContact = {
  fetchContact(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: FETCH_CONTACT,
        variables: {
          id: args.contact_id
        }
      }).then(response => {
        var contact = response.data.fetch_contact;
        res(contact);
      });
    });
  }
};
