import { CREATE_ESTIMATE } from 'queries/estimates/create.js';
import { flashMessageButtons } from  'concerns/flash_message_buttons.js';

export const createEstimate = {
  createEstimate(context, args) {
    var disable_flash = true
    if(!args.continue_edit && window.location.pathname == '/admin/estimates') {
      disable_flash = false;
    }
    return new Promise((res, rej) => {
      args.apollo.mutate({
        mutation: CREATE_ESTIMATE,
        variables: {
          ...args.newEstimate, disable_flash: disable_flash
        }
      }).then(response => {
        var data = response.data.createEstimate.estimate;
        args.component.loader = false;

        context.commit('setNewEstimate', data);
        if (Object.keys(data.errors).length) {
          return;
        }
        res();
        if(args.continue_edit) {
          window.location = router.makePath('order_edit', { id: data.code });
        } else {
          var buttons = { buttons:
            flashMessageButtons([
              ['Continue Editing', data.edit_url],
              ['View', data.show_url],
            ])
          }
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['estimates_list'] } }));
          args.showAlert('alert-success', response.data.createEstimate.success_message, buttons);
        }
      });
    });
  }
};
