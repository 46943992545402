import { createHelpers } from 'vuex-map-fields';

const { getEstimate } = createHelpers({
  getterType: 'getEstimate'
});

export const initialState = () => {
  return {
    id: null,
    name: '',
    account_id: null,
    contact_id: null,
    state: 'estimate',
    in_hands_date: null,
    errors: []
  }
}

export const State = {
  newEstimate: {
    ...initialState()
  },
};

export const Getters = {
  getEstimate
};
