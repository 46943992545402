import { createHelpers } from 'vuex-map-fields';

const { getCreateWorkOrder } = createHelpers({
  getterType: 'getCreateWorkOrder'
});

export const initialState = () => {
  return {
    id: null,
    linked_transaction_id: null,
    title: '',
    account_id: null,
    contact_id: null,
    activity_id: null,
    accountOptions: [],
    internal: false,
    wo_contact_present: false,
    account_details: {},
    contact_details: {},
    errors: {},
    quick_modal_object: true,
    skip_validations: true,
    virtual_due_datetime_attributes_needed: true,
    due_date: '',
    due_time: '',
    parent_work_order: '',
    type: ''
  }
}

export const State = {
  modalHeader: '',
  newWorkOrder: initialState()
};

export const Getters = { getCreateWorkOrder };
