import { CREATE_WORK_ORDER } from 'queries/work_orders/create';
import { formatErrors } from 'concerns/format_errors.js';
import { workOrderEditPath } from 'concerns/path_helper_methods'

export const createWorkOrder = {
  createWorkOrder({ commit }, { apollo, workOrderData, component, showAlert, continue_edit }) {
    return new Promise((res, rej) => {
      apollo.mutate({
        mutation: CREATE_WORK_ORDER,
        variables: {
          ...workOrderData,
          continue_edit: continue_edit,
          filters: window.location.search
        }
      }).then(response => {
        const work_order = response.data.createWorkOrder.work_order
        component.loader = false;
        if (Object.keys(work_order.errors).length) {
          component.errors = formatErrors(work_order.errors)
          if (component.errors.base_refresh) {
            window.location.reload()
          }
          if(component.errors.base) {
            showAlert('alert-error', ...component.errors.base)
          }
        } else {
          if (continue_edit) {
            location.href = workOrderEditPath(work_order)
          } else {
            $('div[data-board-update-url="/admin/work_orders/board_updates"]').data('last-activity-hash', response.data.createWorkOrder.last_activity_hash);
            component.newWorkOrder = work_order
            showAlert('alert-success', response.data.createWorkOrder.success)
            res()
          }
        }
      }).catch((reason) => {
        showAlert('alert-error', reason.graphQLErrors[0].message)
      });
    });
  }
};
