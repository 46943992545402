import { createHelpers } from 'vuex-map-fields';

const { getAccount } = createHelpers({
  getterType: 'getAccount'
});

export const State = {
  account_ap_contact_options: [],
  serializedAccount: {},
  payments: [],
  invoices: [],
  orders: [],
  estimates: [],
  opportunities: [],
  contacts: [],
  currentAccount: {
    flags: [],
    holds: []
  },
  holds: [],
  contactSources: [],
  accountModalStatus: false,
  skipAccountValidation: false,
  createdAccountData: {},
  current_account_object: {
    name: '',
    abbreviated_name: '',
    estimated_spend: '',
    taxjar_customer_id: '',
    email: '',
    qualification_criteria: {
      funding_raised_range: '',
      large_customer_affilates: false,
      special_event: false,
      employees_count_range: '',
      errors: {}
    },
    errors: {}
  },
  edit_account_object: {
    name: '',
    qualification_criteria_attributes: {
      funding_raised_range: '',
      large_customer_affilates: false,
      special_event: false,
      employees_count_range: '',
      errors: {}
    },
    credit_information_attributes: {
      credit_limit: null,
      payment_method: null,
      term: null,
      errors: {}
    },
    facebook_link_attributes: {
      link: '',
      errors: {}
    },
    twitter_link_attributes: {
      link: '',
      errors: {}
    },
    linked_in_link_attributes: {
      link: '',
      errors: {}
    },
    crunchbase_link_attributes: {
      link: '',
      errors: {}
    },
    ap_contact_id: null,
    errors: {}
  },
  ajaxRequestSent: false,
  currentTab: 'contacts',
  accountCreateSuccessful: false,
  loading: false,
  accountAvailableCreditLimitDetailsModal: false,
  availableCreditLimitDetails: {}
};

export const Getters = {
  getAccount
};

Getters['unresolvedFlags'] = function (state) {
  if (state.currentAccount && state.currentAccount.name) {
    return state.currentAccount.flags.filter((flag) => !flag.is_resolved)
  }
  return []
}

Getters['unresolvedHolds'] = function (state) {
  if (state.currentAccount && state.currentAccount.name) {
    return state.currentAccount.holds.filter((flag) => !flag.is_resolved)
  }
  return []
}
