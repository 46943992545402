import { FETCH_ACCOUNT_TOOLTIP_DATA } from 'queries/accounts/fetch_account_tooltip_data.js';

export const fetchAccountData = {
  fetchAccountData(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: FETCH_ACCOUNT_TOOLTIP_DATA,
        variables: {
          id: args.accountId.toString()
        }
      }).then(response => {
        context.commit('updateAccountsData', response.data.fetch_account);
        res();
      });
    });
  }
};
