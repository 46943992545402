import { FETCH_PO_SHIPPING_ADDRESS_DATA } from 'queries/purchase_orders/fetch_shipping_address.js';

export const fetchPOShippingAddressData = {
  fetchPOShippingAddressData(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: FETCH_PO_SHIPPING_ADDRESS_DATA,
        variables: {
          code: args.code.toString(),
          order_code: args.order_code.toString()
        }
      }).then(response => {
        context.commit('updatePOShippingAddressData', response.data.show_purchase_order);
        res();
      });
    });
  }
};
