import gql from 'graphql-tag';

export const UPDATE_COMMENT_QUERY = gql `
  mutation($id: String!, $body: String, $urgent: Boolean) {
    updateComment(input: { id: $id, body: $body, urgent: $urgent }) {
      comment {
        id
        body
        urgent
        inbound
        from_name
        from_email
        updated_at
        created_at
        seconds_to_cutoff
        unformatted_body
        comments_hash
        can_be_edited
        creator_id
        errors {
          field_name,
          errors
        }
      }
    }
  }
`;
