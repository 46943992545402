import gql from 'graphql-tag';

export const UPDATE_TASK_ATTACHMENTS_QUERY = gql `
  mutation($input: UpdateTaskAttachmentsInput!) {
    updateTaskAttachments(input: $input) {
      lock_version
      attachments {
        name
        file_icon
        document_file_name
        document_url
        size_in_kb
        thumbnail_url
      }
    }
  }
`;
