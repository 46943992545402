import gql from 'graphql-tag';

export const CREATE_TIME_LOG_QUERY = gql `
  mutation($input: CreateTimeLogInput!) {
    createTimeLog(input: $input) {
      time_log {
        id
        duration
        description
        lock_version
        loggable_id
        editabled_till
        loggable_type
        seconds_to_cutoff
        editable
        loggable_date
        loggable {
          id
          formatted_total_time_logged
          total_time_logged
        }
        errors {
          field_name
          errors
        }
      }
    }
  }
`;
