import gql from 'graphql-tag';

export const DELETE_WORK_ORDER = gql `
  mutation($input: DeleteWorkOrderInput!) {
    deleteWorkorder(input: $input) {
      status
      message
      last_activity_hash
    }
  }
`;
