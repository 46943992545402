import gql from 'graphql-tag';

export const FETCH_ACCOUNTS_QUERY = gql`
  query {
    fetch_accounts {
      id
      text
      label
      value
      target
      favicon_image
    }
  }
`;
