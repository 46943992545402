import gql from 'graphql-tag';

export const MARK_TASK_INCOMPLETE = gql `
  mutation($id: String!) {
    markTaskIncomplete(input: { id: $id }) {
      task {
        id
        lock_version
        restrict_editable
        is_completed
        loggable_name
        is_cancelled
        cancelled_at
        updated_at
        errors {
          field_name
          errors
        }
      }
    }
  }
`;
