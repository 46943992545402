import gql from 'graphql-tag';

export const UPDATE_TASK_FOLLOWERS_QUERY = gql `
  mutation($input: UpdateTaskFollowersInput!) {
    updateTaskFollowers(input: $input) {
      lock_version
      followers {
        id
      }
    }
  }
`;
