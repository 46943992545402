import { FETCH_CONTACT } from 'queries/contacts/fetch_contact.js';

export const fetchContact = {
  fetchContact({ commit }, { apollo, contact_id, componentObject }) {
    return new Promise((res, rej) => {
      apollo.query({
        query: FETCH_CONTACT,
        variables: {
          id: contact_id.toString()
        }
      }).then(response => {
        commit('updateCurrentContact', response.data.fetch_contact);
        res();
      });
    });
  }
};
