import { TASK_COMMENTS } from 'queries/tasks/task_comments';

export const loadTaskComments = {
  loadTaskComments: function(context, args) {
    return new Promise((res, rej) => {
      var apollo = args.apollo;
      apollo.query({
        query: TASK_COMMENTS,
        variables: { id: args.task_id }
      })
      .then(response => {
        var task = response.data.task,
          task_comments = task.comments;
        context.commit('updateTaskComments', task_comments);
        context.commit('updateCommentsCount', task.comments_count);
        res(task_comments);
        args.componentObject.ajaxRequestSent = false;
        args.componentObject.loading_comments = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        args.componentObject.loading_comments = false;
      });
    });
  }
};
