import gql from 'graphql-tag';

export const TASK_COMMENTS = gql `
  query task($id: String!) {
    task(id: $id) {
      id
      comments_count
      comments {
        id
        body
        from_name
        from_email
        inbound
        created_at
        updated_at
        can_be_edited
        seconds_to_cutoff
        unformatted_body
        urgent
        show_path
        creator_id
      }
    }
  }
`;
