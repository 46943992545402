import * as deepmerge from 'deepmerge';

import { mutatePermissions } from "store/data/permissions/mutations/mutate_permissions";
import { mutateDynamicPermissions } from "store/data/permissions/mutations/mutate_dynamic_permissions";

import { updatePermissions } from "store/data/permissions/actions/update_permissions";
import { getDynamicPermission } from "store/data/permissions/actions/get_dynamic_permissions";

import { State, Getters } from 'store/data/permissions/state.js';
import { createHelpers } from 'vuex-map-fields';

const { updatePermission } = createHelpers({
  mutationType: 'updatePermission',
});

const Actions = deepmerge.all([updatePermissions, getDynamicPermission]);
const Mutations = deepmerge.all([mutatePermissions, mutateDynamicPermissions]);

Mutations['updatePermission'] = updatePermission;

export const Permission = {
  actions: Actions,
  mutations: Mutations,
  state: State,
  getters: Getters,
  namespaced: true
};
