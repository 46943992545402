import { createHelpers } from 'vuex-map-fields';

const { getContact } = createHelpers({
  getterType: 'getContact'
});

export const State = {
  addresses: [],
  payments: [],
  orders: [],
  invoices: [],
  estimates: [],
  opportunities: [],
  referred_contacts: [],
  accountTeamId: null,
  currentContact: null,
  preselected_account_id: '',
  current_contact_object: {
    name: '',
    email: '',
    phone: '',
    mobile_number: '',
    title: '',
    section: '',
    type: 'Lead',
    lead_status: 'new',
    quick_modal_object: true,
    account: {},
    account_team: {},
    errors: {}
  },
  edit_contact_object: {
    name: '',
    section: '',
    type: '',
    email_id: '',
    owner_id: '',
    account_id: '',
    phone: '',
    mobile_number: '',
    title: '',
    account_team_id: null,
    account_name: '',
    referral_source: '',
    communication_referral_attributes: {
      referral_contact_id: '',
      referral_type: ''
    },
    department: {
      id: null,
      name: null
    },
    social_referral_attributes: {
      referral_type: '',
      special_event_content: '',
      special_event_attributes: {
        id: '',
        name: ''
      }
    },
    errors: {}
  },
  ajaxRequestSent: false,
  contactCreateSuccessful: false
};

 export const Getters = {
  getContact
};
