import { VALIDATE_ACCOUNT_QUERY } from 'queries/accounts/validate_account';
import { formatErrors } from  'concerns/format_errors.js';

export const requestAccountValid = {
  requestAccountValid: function(context, args) {
    var input = {
      name: context.state.current_account_object.name,
      email: context.state.current_account_object.email,
      abbreviated_name: context.state.current_account_object.abbreviated_name,
      taxjar_customer_id: context.state.current_account_object.taxjar_customer_id,
      potential_rating: context.state.current_account_object.potential_rating,
      estimated_spend: context.state.current_account_object.estimated_spend,
      qualification_criteria_attributes: {
        employees_count_range: context.state.current_account_object.qualification_criteria.employees_count_range || '',
        large_customer_affilates: context.state.current_account_object.qualification_criteria.large_customer_affilates,
        special_event: context.state.current_account_object.qualification_criteria.special_event,
        funding_raised_range: context.state.current_account_object.qualification_criteria.funding_raised_range || ''
      }
    };
    args.apollo.mutate({
      mutation: VALIDATE_ACCOUNT_QUERY,
      variables: {
        input: input
      }
    }).then((data) => {
      var account = data.data.validateAccount.account;
      if (account.errors.length) {
        account.errors = formatErrors(account.errors);
        account.qualification_criteria.errors = formatErrors(account.qualification_criteria.errors);
        context.commit('updateCurrentAccountObject', account);
      } else {
        context.commit('updateCurrentAccountObject', account);
        args.componentObject.showConfirmationModal();
      }
      args.componentObject.ajaxRequestSent = false;
    }).catch((reason) => {
      args.showAlert('alert-error', reason.graphQLErrors[0].message)
      args.componentObject.ajaxRequestSent = false;
    });
  }
};
