import { GET_USER_PERMISSIONS } from 'queries/user_permissions/get_dynamic_permission';

export const getDynamicPermission = {
  getDynamicPermission: function (context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: GET_USER_PERMISSIONS,
        variables: {
          resource_type: args.resource_type,
          resource_id: args.resource_id,
        }
      }).then((response) => {
        let permission_set = response.data.get_user_permissions.permission_set;
        if (permission_set) {
          context.commit('mutateDynamicPermissions', { newPermissions: JSON.parse(permission_set), mutateDynamicPermissions: args.mutateDynamicPermissions, getDynamicPermission: args.getDynamicPermission, $apollo: args.apollo });
        }
      })
    })
  }
};
