import { createHelpers } from 'vuex-map-fields';

const { getOpportunity } = createHelpers({
  getterType: 'getOpportunity'
});

export const initialState = () => {
  return {
    id: null,
    name: '',
    state: 'brainstorming',
    account_id: null,
    contact_id: null,
    close_by_datetime: null,
    in_hands_date: null,
    estimated_revenue: null,
    cost_center: null,
    project_number: null,
    client_po_number: null,
    errors: {}
  }
}

export const State = {
  selectedOpportunityId: null,
  preselected_contact_id: null,
  newOpportunity: {
    ...initialState()
  },
  contact_options: []
};

export const Getters = {
  getOpportunity
};
