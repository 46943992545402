import { FETCH_SUB_WORK_ORDER_QUICK_VIEW_DATA } from 'queries/work_orders/sub_work_orders/fetch_sub_work_order_quick_view_data.js';

export const fetchSubWorkOrderQuickViewData = {
  fetchSubWorkOrderQuickViewData: function(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: FETCH_SUB_WORK_ORDER_QUICK_VIEW_DATA,
        variables: {
          id: args.id
        }
      }).then((data) => {
        res(data.data.sub_work_order)
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
      });
    });
  }
};
