import gql from 'graphql-tag';

export const FETCH_PO_SHIPPING_ADDRESS_DATA = gql`
  query show_purchase_order($code: String!, $order_code: String!) {
    show_purchase_order(order_code: $order_code, code: $code) {
      id
      shipping_receivable_name
      shipping_address {
        id
        name
        company_name
        full_address
      }
    }
  }
`;
