import gql from 'graphql-tag';

export const ARCHIVE_TASK_QUERY = gql `
  mutation($id: String!) {
    archiveTask(input: { id: $id }) {
      task {
        id
        is_archived
        updated_at
        errors {
          field_name
          errors
        }
      }
      success
    }
  }
`;
