import gql from 'graphql-tag';

export const CREATE_WORK_ORDER = gql`
  mutation($title: String, $linked_transaction_id: ID, $activity_id: ID, $account_id: ID, $contact_id: ID, $due_date: String, $due_time: String, $parent_work_order: String, $type: String, $quick_modal_object: Boolean, $skip_validations: Boolean, $virtual_due_datetime_attributes_needed: Boolean, $continue_edit: Boolean, $filters: String) {
    createWorkOrder(input: { title: $title, linked_transaction_id: $linked_transaction_id, activity_id: $activity_id, account_id: $account_id, contact_id: $contact_id, due_date: $due_date, due_time: $due_time, parent_work_order: $parent_work_order, type: $type, quick_modal_object: $quick_modal_object, skip_validations: $skip_validations, virtual_due_datetime_attributes_needed: $virtual_due_datetime_attributes_needed, continue_edit: $continue_edit, filters: $filters }) {
      success
      last_activity_hash
      work_order {
        id
        activity {
          id
        }
        errors {
          field_name
          errors
        }
      }
    }
  }
`;
