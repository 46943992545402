import { ORDER_CREATE } from 'queries/orders/create';
import { flashMessageButtons } from  'concerns/flash_message_buttons.js';

export const createOpportunity = {
  createOpportunity(context, args) {
    var enable_flash = !args.continue_edit && window.location.pathname == '/admin/opportunities';
    return new Promise((res, rej) => {
      args.apollo.mutate({
        mutation: ORDER_CREATE,
        variables: {
          ...args.newOpportunity, enable_flash: enable_flash
        }
      }).then(response => {
        var data = response.data.createOrder.order;
        context.commit('setNewOpportunity', data);
        args.component.loader = false;
        if (Object.keys(data.errors).length) {
          return;
        }
        res();
        if(args.continue_edit) {
          window.location = router.makePath('order_edit_with_redirect', { id: data.code, redirect_uri: window.location.href });
        } else {
          var buttons = { buttons:
            flashMessageButtons([
              ['Continue Editing', data.edit_url],
              ['View', data.show_url],
            ])
          }
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['opportunities_list'] } }));
          args.showAlert('alert-success', response.data.createOrder.success_message, buttons);
        }
      });
    });
  }
};
