import { VALIDATE_CONTACT_QUERY } from 'queries/contacts/validate_contact';
import { formatErrors } from  'concerns/format_errors.js';

export const validateQuickContact = {
  validateQuickContact: function(context, args) {
    var input = {
      name: context.state.current_contact_object.name,
      email: context.state.current_contact_object.email,
      lead_status: context.state.current_contact_object.lead_status,
      phone: context.state.current_contact_object.phone,
      mobile_number: context.state.current_contact_object.mobile_number,
      type: context.state.current_contact_object.type,
      account_id: args.account_id || '',
      account_team_id: args.account_team_id || '',
      quick_modal_object: context.state.current_contact_object.quick_modal_object
    };

    return new Promise((res, rej) => {
      args.apollo.mutate({
        mutation: VALIDATE_CONTACT_QUERY,
        variables: {
          input: input
        }
      }).then((data) => {
        var contact = data.data.validateContact.contact;
        args.componentObject.account = contact.account || {};
        args.componentObject.account_team = contact.account_team || {};

        if (contact.errors.length) {
          contact.errors = formatErrors(contact.errors);
          context.commit('updateCurrentContactObject', contact);
        } else {
          context.commit('updateCurrentContactObject', contact);
        }
        res({ contact: contact, warnings: JSON.parse(data.data.validateContact.warnings)})
        context.state.ajaxRequestSent = false;
        args.componentObject.ajaxRequestSent = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        context.state.ajaxRequestSent = false;
      });
    });
  }
};
