import { createHelpers } from 'vuex-map-fields';

const { getTask } = createHelpers({
  getterType: 'getTask'
});

export const State = {
  ajaxRequestSent: false,
  task: {},
  attachment_type: 'TaskAttachment',
  association_name: 'attachments',
  attachment_label_name: 'Attachments',
  modalOptions: {
    title: '',
    modalContainerClass : 'task-detail-modal',
    topBarColor:'#cd58ff',
    modalClasses: '',
    tabindex: '-1',
    keyboard: 'false',
    pickerOverFlowClass: 'no-overflow'
  },
  entity_id: '',
  loading_history: false,
  loading_comments: false,
  task_updated: false,
  taskArchivedSuccessfully: false,
  archivedTaskId: null,
  deletedTaskId: null,
  load_timelog: false,
  modalOpened: false,
};

export const Getters = {
  getTask
}
