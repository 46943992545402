import { TASK_HISTORY } from 'queries/tasks/task_history';

export const loadTaskHistory = {
  loadTaskHistory: function(context, args) {
    return new Promise((res, rej) => {
      var apollo = args.apollo;
      apollo.query({
        query: TASK_HISTORY,
        variables: { id: args.task_id }
      })
      .then(response => {
        var task_history = response.data.task.task_versions;
        context.commit('updateTaskHistory', task_history);
        args.componentObject.ajaxRequestSent = false;
        args.componentObject.loading_history = false;
        res();
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        args.componentObject.loading_history = false;
      });
    });
  }
};
