import gql from 'graphql-tag';

export const ORDER_CREATE = gql `
    mutation($state: String, $name: String, $estimated_revenue: String, $cost_center: String, $project_number: String, $client_po_number: String, $account_id: ID, $contact_id: ID, $close_by_datetime: String, $enable_flash: Boolean) {
    createOrder(input: { state: $state, name: $name, estimated_revenue: $estimated_revenue, cost_center: $cost_center, project_number: $project_number, client_po_number: $client_po_number, account_id: $account_id, contact_id: $contact_id, close_by_datetime: $close_by_datetime, enable_flash: $enable_flash }) {
      order {
        id
        state
        code
        account_id
        edit_url
        show_url
        contact_id
        close_by_datetime
        estimated_revenue
        name
        cost_center
        project_number
        client_po_number
        errors {
          field_name
          errors
        }
      }
      success_message
    }
  }
`;
