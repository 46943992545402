<template>
  <div class='form-error'>
    <span v-for='error in errors'>
      {{ error }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'error',
    props: ['errors'],
    watch: {
      errors: function(val) {
        if(val) {
          $(this.$el).closest('.form-group').addClass('has-error');
        } else {
          $(this.$el).closest('.form-group').removeClass('has-error');
        }
      }
    },
    mounted() {
      if(this.errors) {
        $(this.$el).closest('.form-group').addClass('has-error');
      } else {
        $(this.$el).closest('.form-group').removeClass('has-error');
      }
    }
  }
</script>

<style scoped>
</style>
