import { FETCH_ACCOUNT } from 'queries/contacts/fetch_account.js';

export const fetchAccount = {
  fetchAccount(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: FETCH_ACCOUNT,
        variables: {
          id: args.account_id
        }
      }).then(response => {
        res(response.data.fetch_account);
      });
    });
  }
};
