import { FETCH_ACCOUNTS_QUERY } from 'queries/accounts/fetch_accounts.js';

export const fetchAccounts = {
  fetchAccounts({ commit }, { apollo }) {
    return new Promise((res, rej) => {
      apollo.query({
        query: FETCH_ACCOUNTS_QUERY
      }).then(response => {
        var accounts = response.data.fetch_accounts;
        commit('setAccounts', accounts);
        res(accounts);
      });
    });
  }
};
