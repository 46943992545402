import { CREATE_ACCOUNT_QUERY } from 'queries/accounts/create_account';
import { formatErrors } from  'concerns/format_errors.js';
import { flashMessageButtons } from  'concerns/flash_message_buttons.js';

const setParams = (account) => {
  var url = new URL(window.location.href);
  url.searchParams.set('account_id', account.id);
  return url.href;
}

export const requestAccountCreate = {
  requestAccountCreate: function(context, args) {
    return new Promise((res, rej) => {
      var enable_flash = (window.location.pathname == '/admin/accounts' && !args.continue_editing);
      var input = {
        name: context.state.current_account_object.name,
        email: context.state.current_account_object.email,
        abbreviated_name: context.state.current_account_object.abbreviated_name,
        taxjar_customer_id: context.state.current_account_object.taxjar_customer_id,
        potential_rating: context.state.current_account_object.potential_rating,
        estimated_spend: context.state.current_account_object.estimated_spend,
        qualification_criteria_attributes: {
          employees_count_range: context.state.current_account_object.qualification_criteria.employees_count_range || '',
          large_customer_affilates: context.state.current_account_object.qualification_criteria.large_customer_affilates,
          special_event: context.state.current_account_object.qualification_criteria.special_event,
          funding_raised_range: context.state.current_account_object.qualification_criteria.funding_raised_range || ''
        },
        enable_flash: enable_flash
      };
      args.apollo.mutate({
        mutation: CREATE_ACCOUNT_QUERY,
        variables: {
          input: input
        }
      }).then((data) => {
        var account = data.data.createAccount.account,
          redirect_uri = router.makePath('account_edit_with_redirect', { id: account.id, redirect_uri: setParams(account) });
        if (account.errors.length) {
          account.errors = formatErrors(account.errors);
          account.qualification_criteria.errors = formatErrors(account.qualification_criteria.errors);
          context.commit('updateCurrentAccountObject', account);
        } else {
          if (args.continue_editing) {
            window.location = redirect_uri
          } else if(enable_flash) {
            var buttons = {
              buttons:
                flashMessageButtons([
                  ['Continue Editing', redirect_uri],
                  ['View', account.show_url],
                ])
            }
            window.dispatchEvent(new CustomEvent('paramsUpdated'));
            args.showAlert('alert-success', data.data.createAccount.success_message, buttons);
            res(account);
          } else {
            var buttons = { buttons:
              flashMessageButtons([
                ['Continue Editing', redirect_uri],
                ['View', account.show_url],
              ])
            }
            args.showAlert('alert-success', data.data.createAccount.success_message, buttons);
            res(account);
          }
        }
        args.componentObject.ajaxRequestSent = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
      });
    });
  }
};
