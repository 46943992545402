import { ACCOUNT_SEARCH_QUERY } from 'queries/accounts/search';

export const searchAccounts = {
  searchAccounts: function({ commit }, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: ACCOUNT_SEARCH_QUERY,
        variables: {
          search_string: args.searchTerm
        }
      }).then((response) => {
        res(response.data.search_accounts);
      })
    });
  }
};
