import { createHelpers } from 'vuex-map-fields';

const { getPermission } = createHelpers({
  getterType: 'getPermission'
});

export const State = {
  permissions: {},
  dynamicPermissions: {},
  dynamicPermissionsProxy: {}
};

export const Getters = {
  getPermission
};
