const COMMENT_FIELDS = `
  comments_count
  comment_thread {
    id
  }
  comments {
    id
    body
    from_name
    from_email
    inbound
    created_at
    updated_at
    can_be_edited
    seconds_to_cutoff
    unformatted_body
    comments_hash
    urgent
    show_path
    creator_id
  }
`

export default COMMENT_FIELDS;
