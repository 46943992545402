import gql from 'graphql-tag';

export const DELETE_TASK_QUERY = gql `
  mutation($id: String!) {
    deleteTask(input: { id: $id }) {
      task {
        id
        errors {
          field_name
          errors
        }
      }
      success
    }
  }
`;
