import gql from 'graphql-tag';

export const UPDATE_TASK_QUERY = gql `
  mutation($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
        title
        due_date
        due_time
        description
        virtual_due_date
        virtual_due_time
        is_completed
        is_archived
        is_overdue
        due_today
        loggable_name
        is_cancelled
        assignee_name
        show_path
        is_newly_created
        uuid
        formatted_total_time_logged
        total_time_logged
        presentable_due_time
        presentable_due_date
        formatted_created_at
        lock_version
        cancelled_at
        updated_at
        formatted_updated_at
        task_url
        assignee_id
        creator_id
        creator_name
        task_follower_ids
        attachment_ids
        comment_thread {
          id
        }
        assignee {
          id
          actual_name
          email
          profile_picture_url
          show_url
        }
        task_list {
          id
          taskable_id
          taskable_type
        }
        followers {
          id
        }
        task_category {
          id
          name
        }
        attachables_attachments_attributes {
          _destroy
          id
          attachment_id
          attachment_attributes {
            id
            name
            file_icon
            thumbnail_url
            document_url
            document_file_name
            file_icon_name
            errors {
              field_name
              errors
            }
          }
        }
        errors {
          field_name
          errors
        }
      }
    }
  }
`;
