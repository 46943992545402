import { DELETE_TASK_TIME_LOG } from 'queries/tasks/delete_task_timelog';

export const deleteTaskTimeLog = {
  deleteTaskTimeLog: function(context, args) {
    return new Promise((res, rej) => {
      args.apollo.mutate({
        mutation: DELETE_TASK_TIME_LOG,
        variables: { id: args.logId }
      })
      .then(response => {
        var timelog = response.data.deleteTimeLog;
        if (timelog.success == gon.graphql_states.success) {
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['tasks_list', 'archived_tasks_list'] } }));
          args.showAlert('alert-success', timelog.message)
        } else {
          args.showAlert('alert-error', response.data.deleteTimeLog.timelog.errors[0].errors[0])
        }
        window.pubnubReady = false;
        res();
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        // args.componentObject.load_timelog = false;
      });
    });
  }
};
