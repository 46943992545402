import { CREATE_COMMENT_QUERY } from 'queries/comments/create_comment.js';
import { formatErrors } from  'concerns/format_errors.js';

export const requestCreateComment = {
  requestCreateComment: function(context, args) {

    return new Promise((res, rej) => {
      var input = {
        body: args.comment_object.body,
        urgent: args.comment_object.urgent,
        inbound: args.comment_object.inbound,
        from_name: args.from_name,
        from_email: args.from_email,
        comment_thread_id: args.comment_thread_id || '',
        commentable_id: args.commentable_id || '',
        commentable_type: args.commentable_type || '',
      };

      args.apollo.mutate({
        mutation: CREATE_COMMENT_QUERY,
        variables: {
          input: input
        }
      }).then((response) => {
        var comment = response.data.createComment.comment;

        if (comment.errors.length) {
          comment.errors = formatErrors(comment.errors);
          rej(comment);
          if(!$.isEmptyObject(comment.errors.base)){
            args.showAlert('alert-error', comment.errors.base[0]);
          }
        } else {
          res(comment);
          args.componentObject.ajaxRequestSent = false;
        }


      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        rej();
      });
    });
  }
};
