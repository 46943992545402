import { CREATE_CONTACT_QUERY } from 'queries/contacts/create_contact';
import { formatErrors } from  'concerns/format_errors.js';
import { flashMessageButtons } from  'concerns/flash_message_buttons.js';

const getCurrentList = (contact_type) => {
  if(contact_type == 'Prospect') {
    return ['prospects_list', 'contacts_list'];
  } else if(contact_type == 'Lead') {
    return ['leads_list', 'contacts_list'];
  }
}

const setParams = (contact, should_add_contact_id) => {
  var url = new URL(window.location.href);
  if(contact.type == 'Prospect') {
    url.searchParams.delete('account_id');
    if(should_add_contact_id) {
      url.searchParams.set('contact_id', contact.id);
    }
  }
  return url.href;
}

export const createQuickContact = {
  createQuickContact: function(context, args) {
    var contact_type = context.state.current_contact_object.type,
      enable_flash = false;
    var input = {
      name: context.state.current_contact_object.name,
      email: context.state.current_contact_object.email,
      lead_status: context.state.current_contact_object.lead_status,
      phone: context.state.current_contact_object.phone,
      mobile_number: context.state.current_contact_object.mobile_number,
      type: context.state.current_contact_object.type,
      account_id: args.account_id || '',
      account_team_id: args.account_team_id || '',
      quick_modal_object: context.state.current_contact_object.quick_modal_object,
      enable_flash: enable_flash
    };

    return new Promise((res, rej) => {
      args.apollo.mutate({
      mutation: CREATE_CONTACT_QUERY,
      variables: {
        input: input
      }
      }).then((data) => {
        var contact = data.data.createContact.contact,
          redirect_uri = router.makePath(
            'contact_edit_with_redirect', { id: contact.id, redirect_uri: setParams(contact, !!args.componentObject.common_modal) });
        if (contact.errors.length) {
          contact.errors = formatErrors(contact.errors);
          context.commit('updateCurrentContactObject', contact);
        } else {
          if (args.continue_editing) {
            window.location = redirect_uri;
          } else {
            var buttons = { buttons:
              flashMessageButtons([
                ['Continue Editing', redirect_uri],
                ['View', contact.show_url],
              ])
            }
            args.showAlert('alert-success', data.data.createContact.success_message, buttons);
            res({ contact: contact, listNames: getCurrentList(contact.type) });
          }
          args.componentObject.hideContactFormModal();
        }
        context.state.ajaxRequestSent = false;
        args.componentObject.ajaxRequestSent = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        context.state.ajaxRequestSent = false;
      });
    });
  }
};
