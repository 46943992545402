import { ACCOUNT_MIN_DETAIL } from 'queries/accounts/account_min_detail.js';

export const fetchAccount = {
  fetchAccount({ commit }, { apollo, id }) {
    return new Promise((res, rej) => {
      apollo.query({
        query: ACCOUNT_MIN_DETAIL,
        variables: {
          id: id
        }
      }).then(response => {
        var account = response.data.fetch_account;
        res(account);
      });
    });
  }
};
