import gql from 'graphql-tag';

export const FETCH_ACCOUNT_TOOLTIP_DATA = gql`
  query fetch_account($id: String!) {
    fetch_account(id: $id) {
      id
      name
      number
      potential_rating
      taxjar_customer_id
      estimated_spend
      is_target
      last_ordered_at
      current_year_spend
      profile_picture
    }
  }
`;
