import * as deepmerge from 'deepmerge'
import { hoursAndMinutes } from 'concerns/time_duration_methods.js'

export const updateTimeLogObject = {
  updateTimeLogObject: function(state, time_log_object) {
    state.time_log_object.errors = {};
    const old_duration = state.time_log_object.duration;
    state.time_log_object = deepmerge(state.time_log_object, time_log_object);

    if(!(time_log_object.errors.length)) {
      state.time_log_object.duration = old_duration;
    } else {
      state.time_log_object.duration = hoursAndMinutes(time_log_object.duration);
    }
  }
};
