import { FETCH_CONTACT_TOOLTIP_DATA } from 'queries/contacts/fetch_contact_tooltip_data.js';

export const fetchContactData = {
  fetchContactData(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: FETCH_CONTACT_TOOLTIP_DATA,
        variables: {
          id: args.contactId.toString()
        }
      }).then(response => {
        context.commit('updateContactsData', response.data.fetch_contact);
        res();
      });
    });
  }
};
