import { SHOW_TASK } from 'queries/tasks/show_task';

export const loadTaskDetail = {
  loadTaskDetail: function(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: SHOW_TASK,
        variables: { id: args.task_id }
      }).then(response => {
        var task = response.data.task;
        context.commit('updateTaskObject', task);
        context.commit('updateModalTitle', args.translation.t('task_modal.title', { id:  task.id, task_category: task.task_category.name }));
        args.componentObject.ajaxRequestSent = false;
        // window.dispatchEvent(new CustomEvent('re-render-list', { detail: { listNames: ['archived_tasks_list'] } }));
        res();
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message);
        args.componentObject.ajaxRequestSent = false;
      });
    });
  }
};
