import { fetchWorkOrderQuickViewData } from "store/data/work_orders/show/actions/fetch_work_order_quick_view_data.js";
import { fetchSubWorkOrderQuickViewData } from "store/data/work_orders/show/actions/fetch_sub_work_order_quick_view_data.js";
import { State, Getters } from "store/data/work_orders/show/state/quick_show.js";

import * as deepmerge from 'deepmerge';
import { createHelpers } from 'vuex-map-fields';

const Actions = deepmerge.all([
  fetchWorkOrderQuickViewData,
  fetchSubWorkOrderQuickViewData
]);

const Mutations = deepmerge.all([
]);

const { updateWorkOrderQuickShow } = createHelpers({
  mutationType: 'updateWorkOrderQuickShow',
});

Mutations['updateWorkOrderQuickShow'] = updateWorkOrderQuickShow;

export const WorkOrderQuickViewWidget = {
  actions: Actions,
  state: State,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
