import { createHelpers } from 'vuex-map-fields';

const { getWorkOrderQuickShow } = createHelpers({
  getterType: 'getWorkOrderQuickShow'
});

export const Getters = {
  getWorkOrderQuickShow
};

export const State = {
  quickViewWorkOrderId: null,
  quickViewSubWorkOrderId: null
};
