import gql from 'graphql-tag';

export const ACCOUNT_MIN_DETAIL = gql`
  query fetch_account($id: String!) {
    fetch_account(id: $id) {
      id
      text
      label
      value
      target
      favicon_image
    }
  }
`
