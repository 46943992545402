import { State, Getters } from "store/data/estimates/state";
import * as deepmerge from 'deepmerge';

// Actions
import { createEstimate } from "store/data/estimates/actions/create_estimate.js";
import { fetchAccounts } from 'store/data/estimates/actions/fetch_accounts.js';
import { fetchAccount } from 'store/data/opportunities/actions/fetch_account.js';

// Mutations
import { setNewEstimate } from 'store/data/estimates/mutations/set_new_estimate.js';
import { setAccounts } from 'store/data/estimates/mutations/set_accounts.js';

const Actions = deepmerge.all([createEstimate, fetchAccounts, fetchAccount]);
const Mutations = deepmerge.all([setNewEstimate, setAccounts]);

import { createHelpers } from 'vuex-map-fields';

const { updateEstimate } = createHelpers({
  getterType: 'getEstimate',
  mutationType: 'updateEstimate'
});


Mutations['updateEstimate'] = updateEstimate;

export const CreateEstimateWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
