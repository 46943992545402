import { UPDATE_TASK_ATTACHMENTS_QUERY } from 'queries/tasks/update_task_attachments.js';
import { formatErrors } from  'concerns/format_errors.js';

export const updateTaskAttachments = {
  updateTaskAttachments: function(context, args) {
    var input = {
      id: args.loggable_id.toString(),
      attachment_ids: args.attachment_ids.map((element)=> element.toString())
    };

    return new Promise((res, rej) => {

      var apollo = args.apollo;
      apollo.mutate({
        mutation: UPDATE_TASK_ATTACHMENTS_QUERY,
        variables: {
          input: input
        }
      })
      .then(response => {
        var attachments = response.data.updateTaskAttachments.attachments;
        context.commit('updateAttachments', attachments);
        context.commit('updateLockVersion', response.data.updateTaskAttachments.lock_version);
        args.componentObject.ajaxRequestSent = false;
        window.pubnubReady = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
      });
    });
  }
};
