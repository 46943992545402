import { UPDATE_TIME_LOG_QUERY } from 'queries/time_log/update_time_log.js';
import { formatErrors } from  'concerns/format_errors.js';

export const updateNewTimeLog = {
  updateNewTimeLog: function(context, args) {
    var input = {
      duration: context.state.time_log_object.duration,
      description: context.state.time_log_object.description,
      loggable_type: args.loggable_type,
      loggable_id: args.loggable_id,
      id: context.state.time_log_object.id,
      loggable_date: context.state.time_log_object.loggable_date
    };

    return new Promise((res, rej) => {
      args.apollo.mutate({
        mutation: UPDATE_TIME_LOG_QUERY,
        variables: {
          input: input
        }
      }).then((data) => {

        var time_log = data.data.updateTimeLog.timeLog;

        if (time_log.errors.length) {
          time_log.errors = formatErrors(time_log.errors);
          context.commit('updateTimeLogObject', time_log);

          if(!$.isEmptyObject(time_log.errors.base)) {
            args.showAlert('alert-error', time_log.errors.base[0]);
          }

        } else {
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['tasks_list', 'archived_tasks_list'] } }));
          res();
        }

        args.componentObject.ajaxRequestSent = false;
        window.pubnubReady = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        rej();
      });
    });
  }
};
