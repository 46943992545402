import * as deepmerge from 'deepmerge';

const retrieveDynamicPermissions = (args, destructuredProp, prop) => {
  if (args.mutateDynamicPermissions) {
    let propHash = { newPermissions: {} };
    propHash.newPermissions[prop] = false;
    args.mutateDynamicPermissions({ ...args, ...propHash, ...{ resetAll: false } });
  }
  args.getDynamicPermission({
    apollo: args.$apollo,
    getDynamicPermission: args.getDynamicPermission,
    mutateDynamicPermissions: args.mutateDynamicPermissions,
    resource_type: destructuredProp[1],
    resource_id: destructuredProp[2],
  });
}

export const mutateDynamicPermissions = {
  mutateDynamicPermissions: function (state, args) {
    if (args.resetAll) {
      state.dynamicPermissions = {}
    } else {
      state.dynamicPermissions = deepmerge.all([state.dynamicPermissions, args.newPermissions]);
    }

    state.dynamicPermissionsProxy = new Proxy(state.dynamicPermissions, {
      get: function (target, prop, _receiver) {
        if (target.hasOwnProperty(prop) === false) {
          let destructuredProp = (typeof(prop) === 'string') && prop.split("__");
          if (destructuredProp && destructuredProp[1] && destructuredProp[2] && destructuredProp[2] !== 'null' && destructuredProp[2] !== 'undefined') {
            retrieveDynamicPermissions(args, destructuredProp, prop)
          } else if (destructuredProp && destructuredProp[1] && destructuredProp[2] && (destructuredProp[2] === 'null' || destructuredProp[2] === 'undefined')) {
            return true;
          } else {
            return Reflect.get(...arguments);
          }
        } else {
          return Reflect.get(...arguments);
        }
      },
    });
  }
};
