import gql from 'graphql-tag';

export const DELETE_COMMENT_QUERY = gql `
  mutation($id: String!) {
    deleteComment(input: { id: $id }) {
      comment {
        id
        body
        urgent
        inbound
        from_name
        from_email
        creator_id
        errors {
          field_name,
          errors
        }
      }
    }
  }
`;
