import gql from 'graphql-tag';

export const CANCEL_WORK_ORDER = gql `
  mutation($input: CancelWorkOrderInput!) {
    cancelWorkorder(input: $input) {
      status
      message
      last_activity_hash
    }
  }
`;
