export const formatDate = function(value) {
  var availableFormats = [
    'YYYY-MM-DDTHH:mm:ss.SSSZ',
    'YYYY-MM-DD HH:mm:ss ZZ',
    // This format is added to parse GraphQL::Types::ISO8601DateTime in any form
    'YYYY-MM-DDTHH:mm:ssZ',
    'MM/DD/YYYY',
    'd B Y'
  ];
  if (value) {
    for(var formatIndex = 0; formatIndex < availableFormats.length - 1; formatIndex++){
      var parsedValue = moment(value, availableFormats[formatIndex], true);
      var dateFormatted = moment.tz(parsedValue, 'America/Los_Angeles').format('MM/DD/YYYY');

      if(dateFormatted !== 'Invalid date') {
        return dateFormatted;
      }
    }
  }
  return '';
}
