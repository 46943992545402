import gql from 'graphql-tag';

export const ARCHIVE_SUB_WORK_ORDER = gql `
  mutation($input: ArchiveSubWorkOrderInput!) {
    archiveSubWorkorder(input: $input) {
      status
      message
      last_activity_hash
    }
  }
`;
