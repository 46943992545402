import NotificationWidgetModule from 'store/data/notifications_widget/modules/notificationWidget';
import { AccountQuickAddWidget } from 'store/data/accounts/modules/account_quick_add_widget';
import { ContactQuickAddWidget } from 'store/data/contacts/modules/contact_quick_add_widget';
import { TaskQuickAddWidget } from 'store/data/tasks/modules/task_quick_add_widget';
import { AddCommentWidget } from 'store/data/comments/modules/add_comment_widget';
import { TaskDetailModalWidget } from 'store/data/task_detail_modal/modules/task_detail_modal_widget';
import { CreateEstimateWidget } from 'store/data/estimates/modules/create_estimate_widget.js';
import { TimeLogModalWidget } from 'store/data/time_log_modal/modules/time_log_modal_widget';
import { QuickAddOpportunityWidget } from 'store/data/opportunities/modules/quick_add_opportunity_widget.js';
import { QuickAddWorkOrderWidget } from 'store/data/work_orders/create/modules/quick_add_work_order_widget';
import { GeneralConfiguration } from 'store/data/general_configuration/modules/general_configuration.js';
import { AccountSearcher } from 'store/data/shared/modules/account_searcher.js';
import { WorkOrderQuickViewWidget } from 'store/data/work_orders/show/modules/work_order_quick_view_widget.js';
import { WorkOrderQuickActions } from 'store/data/work_orders/smart_list/modules/work_order_quick_actions.js';
import { Permission } from 'store/data/permissions/modules/index';

export const Modules = {
  AccountSearcher,
  NotificationWidgetModule,
  AccountQuickAddWidget,
  QuickAddOpportunityWidget,
  ContactQuickAddWidget,
  TaskQuickAddWidget,
  AddCommentWidget,
  TaskDetailModalWidget,
  GeneralConfiguration,
  CreateEstimateWidget,
  TimeLogModalWidget,
  QuickAddWorkOrderWidget,
  WorkOrderQuickViewWidget,
  WorkOrderQuickActions,
  Permission
};
