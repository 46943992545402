import { MARK_TASK_COMPLETE } from 'queries/tasks/mark_task_complete';
import { formatErrors } from  'concerns/format_errors.js';

export const markComplete = {
  markComplete: function(context, args) {

    var input = {
      id: args.loggable_id,
      time_logs_attributes: {
        duration: args.duration,
        loggable_date: args.loggable_date,
        description: args.description,
        user_id: args.user_id
      }
    };

    return new Promise((res, rej) => {
      var apollo = args.apollo;
      apollo.mutate({
        mutation: MARK_TASK_COMPLETE,
        variables: {
          input: input
        }
      })
      .then(response => {
        var task = response.data.markTaskComplete.task;

        var time_log = response.data.markTaskComplete.time_log;

        if(time_log){
          time_log.errors = formatErrors(time_log.errors);
        }

        if(!$.isEmptyObject(task.errors.base)) {
          var alertMessage = task.errors.base[0];
          args.showAlert('alert-error', alertMessage);
        } else {
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['archived_tasks_list'] } }));
        }
        if(context.state.modalOpened){
          context.commit('updateTaskObject', task);
        }
        args.componentObject.ajaxRequestSent = false;
        window.pubnubReady = false;
        res({ task: task, time_log: time_log });

      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        rej();
      });
    });
  }
};
