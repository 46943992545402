import { DELETE_WORK_ORDER } from 'queries/work_orders/delete_work_order.js';

export const deleteWorkOrder = {
  deleteWorkOrder: function(_, args) {
    const input = {
      work_order_id: args.workOrderId,
      filters: window.location.search
    };
    return new Promise(res => {
      args.apollo.mutate({
        mutation: DELETE_WORK_ORDER,
        variables: { input }
      }).then(response => {
        var result = response.data.deleteWorkorder;
        if (result.status == gon.graphql_states.success) {
          args.showAlert('alert-success', result.message)
          res()
        } else {
          args.showAlert('alert-error', result.message);
        }
        $('div[data-board-update-url="/admin/work_orders/board_updates"]').data('last-activity-hash', result.last_activity_hash);
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
      });
    });
  }
};
