import gql from 'graphql-tag';

export const TASK_HISTORY = gql `
  query task($id: String!) {
    task(id: $id) {
      id
      task_versions {
        event
        version_event
        associated_version_event
        version_item_type_is_follow
        version_item_type_is_attachment
        version_item_type
        version_item_id
        version_item_name
        attachment_name
        created_at
        assignee_changes_ids
        due_date_changes
        follower_id
        author_id
        change_set{
          key
          from_value
          to_value
        }
      }
    }
  }
`;
