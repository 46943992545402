import { CREATE_TIME_LOG_QUERY } from 'queries/time_log/create_time_log';
import { formatErrors } from  'concerns/format_errors.js';

export const requestCreateTimeLog = {
  requestCreateTimeLog: function(context, args) {
    var input = {
      loggable_id: args.loggable_id,
      loggable_type: args.loggable_type,
      duration: args.duration,
      description: args.description,
      loggable_date: args.loggable_date,
    };

    return new Promise((res, rej) => {

      args.apollo.mutate({
        mutation: CREATE_TIME_LOG_QUERY,
        variables: {
          input: input
        }
      }).then((data) => {

        var time_log = data.data.createTimeLog.time_log;
        var loggable = time_log.loggable;

        if (time_log.errors.length) {
          time_log.errors = formatErrors(time_log.errors);

          if(!$.isEmptyObject(time_log.errors.base)) {
            args.showAlert('alert-error', time_log.errors.base[0]);
          }
          context.commit('updateTimeLogObject', time_log);
        } else {
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['tasks_list', 'archived_tasks_list'] } }));
          res({ loggable: loggable, time_log: time_log});
        }

        args.componentObject.ajaxRequestSent = false;
        window.pubnubReady = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        rej();
      });
    });
  }
};
