import gql from 'graphql-tag';

export const CREATE_ACCOUNT_QUERY = gql `
  mutation($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account {
        id
        name
        email
        abbreviated_name
        taxjar_customer_id
        potential_rating
        estimated_spend
        edit_url
        show_url
        is_target
        qualification_criteria {
          funding_raised_range
          special_event
          large_customer_affilates
          employees_count_range
          errors {
            field_name
            errors
          }
        }
        errors {
          field_name
          errors
        }
        creator {
          id
          name
          show_url
          profile_picture_url
        }
      }
      success_message
    }
  }
`;
