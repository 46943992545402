import gql from 'graphql-tag';

export const CREATE_ESTIMATE = gql `
    mutation($state: String, $name: String, $account_id: ID, $contact_id: ID, $in_hands_date: String, $disable_flash: Boolean) {
    createEstimate(input: { state: $state, name: $name, account_id: $account_id, contact_id: $contact_id, in_hands_date: $in_hands_date, disable_flash: $disable_flash }) {
      estimate {
        id
        code
        state
        code
        edit_url
        show_url
        account_id
        contact_id
        in_hands_date
        name
        errors {
          field_name
          errors
        }
      }
      success_message
    }
  }
`;
