import { cancelWorkOrder } from "store/data/work_orders/show/actions/cancel_work_order.js";
import { cancelSubWorkOrder } from "store/data/work_orders/sub_work_orders/show/actions/cancel_sub_work_order.js";

import { deleteWorkOrder } from "store/data/work_orders/show/actions/delete_work_order.js";
import { deleteSubWorkOrder } from "store/data/work_orders/sub_work_orders/show/actions/delete_sub_work_order.js";

import { archiveWorkOrder } from "store/data/work_orders/show/actions/archive_work_order.js";
import { archiveSubWorkOrder } from "store/data/work_orders/sub_work_orders/show/actions/archive_sub_work_order.js";

import * as deepmerge from 'deepmerge';

const Actions = deepmerge.all([
  cancelWorkOrder,
  cancelSubWorkOrder,
  deleteWorkOrder,
  deleteSubWorkOrder,
  archiveWorkOrder,
  archiveSubWorkOrder
]);

export const WorkOrderQuickActions = {
  actions: Actions,
  namespaced: true
};
