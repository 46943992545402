import gql from 'graphql-tag';

export const CANCEL_SUB_WORK_ORDER = gql `
  mutation($input: CancelSubWorkOrderInput!) {
    cancelSubWorkorder(input: $input) {
      status
      message
      last_activity_hash
    }
  }
`;
