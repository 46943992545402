<template>
  <input ref='dateField' type="text" placeholder="mm/dd/yyyy" class="form-control place-holder" :class='class_names' :id='id' :disabled='disabled' @keydown.esc.stop="$emit('escapePressed')" @focusout="$emit('focusOut')" @focus="$emit('focusIn')" autocomplete="off">
</template>

<script>
  import { formatDate } from 'concerns/format_date.js';

  export default {
    name: 'dateField',
    props: ['value', 'class_names', 'id', 'not_format', 'disabled', 'format_on_update'],
    mounted() {
      var vm = this,
          $elem = $(this.$el);

      $elem.datetimepicker({
        validateOnBlur: false,
        scrollInput: false,
        timepicker: false,
        format: $elem.data('date-format') || 'm/d/Y',
        defaultDate: $elem.data('default-date') || false,
        defaultTime: $elem.data('default-time') || false,
        maxDate: $elem.data('max-date') || false
      })
      .val(this.formattedDate(this.value))
      .trigger('change')
      .on('change', function () {
        vm.$emit('input', this.value);
        vm.emitChangeEvent(this.value);
      })
    },
    watch: {
      value: function (value) {
        var finalValue = value
        if(this.format_on_update) {
          finalValue = this.formattedDate(value)
        }
        $(this.$el)
          .val(finalValue)
          .trigger('change')
      }
    },
    methods: {
      emitChangeEvent(value) {
        if(this.value !== value) {
          this.$emit('changed');
        }
      },
      formattedDate(date) {
        if (this.not_format) {
          var dateFormatted = this.value;
        } else {
          var dateFormatted = formatDate(date);
        }
        return dateFormatted;
      },
      focusField() {
        var _this = this;
        this.$nextTick(function() {
          $(this.$refs.dateField).focus();
        });
      }
    }
  }
</script>

<style scoped>
</style>
