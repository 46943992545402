import gql from 'graphql-tag';

export const MARK_TASK_COMPLETE = gql `
  mutation($input: MarkCompleteInput!) {
    markTaskComplete(input: $input) {
      task {
        id
        restrict_editable
        loggable_name
        is_cancelled
        total_time_logged
        lock_version
        is_completed
        cancelled_at
        updated_at
        errors {
          field_name
          errors
        }
      }
      time_log {
        duration
        loggable_date
        description
        errors {
          field_name
          errors
        }
      }
    }
  }
`;
