import gql from 'graphql-tag';

export const TASK_TIME_LOG = gql `
  query task($id: String!) {
    task(id: $id) {
      id
      total_time_logs
      total_time_logged
      lock_version
      time_logs {
        description
        seconds_to_cutoff
        id
        loggable_short_date
        loggable_date
        editable
        duration
        editabled_till
        formatted_duration
        user_id
        user {
          name
          show_url
          id
        }
      }
    }
  }
`;
