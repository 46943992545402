import { DELETE_TASK_QUERY } from 'queries/tasks/delete_task.js';
import { formatErrors } from  'concerns/format_errors.js';

export const requestDeleteTask = {
  requestDeleteTask({ commit }, { apollo, task_id, showAlert }) {

    return new Promise((res, rej) => {
      apollo.mutate({
        mutation: DELETE_TASK_QUERY,
        variables: {
          id: task_id
        }
      }).then(response => {
        var task = response.data.deleteTask.task;
        if(task.errors.length) {
          task.errors = formatErrors(task.errors);
          if(task.errors.base) {
            showAlert('alert-danger', task.errors.base[0]);
          }
        } else {
          var success = response.data.deleteTask.success;
          window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['archived_tasks_list'] } }));
          window.dispatchEvent(new CustomEvent('taskDeleted'));
          showAlert('alert-success', success);
          res(task);
        }
      });
    });
  }
};
