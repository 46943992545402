import gql from 'graphql-tag';

export const FETCH_CONTACT = gql`
  query fetch_contact($id: String!) {
    fetch_contact(id: $id) {
      id
      name
      account {
        id
        name
        is_target
      }
    }
  }
`
