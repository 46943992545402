import { State, Getters } from "store/data/opportunities/state";
import * as deepmerge from 'deepmerge';
import { createHelpers } from 'vuex-map-fields';

// Actions
import { createOpportunity } from 'store/data/opportunities/actions/create_opportunity.js';
import { fetchContact } from 'store/data/opportunities/actions/fetch_contact.js';
import { fetchAccount } from 'store/data/opportunities/actions/fetch_account.js';

// Mutations
import { setNewOpportunity } from 'store/data/opportunities/mutations/set_new_opportunity.js';

const { updateOpportunity } = createHelpers({
  mutationType: 'updateOpportunity',
});

const Actions = deepmerge.all([createOpportunity, fetchContact, fetchAccount]);
const Mutations = deepmerge.all([setNewOpportunity]);

Mutations['updateOpportunity'] = updateOpportunity;

export const QuickAddOpportunityWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
