import { TASK_TIME_LOG } from 'queries/tasks/task_timelog';

export const loadTaskTimeLog = {
  loadTaskTimeLog: function(context, args) {
    return new Promise((res, rej) => {
      var apollo = args.apollo;
      apollo.query({
        query: TASK_TIME_LOG,
        variables: { id: args.task_id }
      })
      .then(response => {
        var time_logs = response.data.task.time_logs;
        var total_time_logs = response.data.task.total_time_logs;
        var lock_version = response.data.task.lock_version;
        context.commit('updateTaskTimeLog', { lock_version: lock_version, time_logs: time_logs, total_time_logs: total_time_logs, total_time_logged: response.data.task.total_time_logged });
        args.componentObject.ajaxRequestSent = false;
        args.componentObject.load_timelog = false;
        res();
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        args.componentObject.load_timelog = false;
      });
    });
  }
};
