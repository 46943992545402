import { requestAccountCreate } from "store/data/accounts/actions/request_account_create";
import { requestAccountValid } from "store/data/accounts/actions/request_account_valid";
import { updateCurrentAccountObject } from "store/data/accounts/mutations/update_current_account_object";
import { State, Getters } from "store/data/accounts/state";
import * as deepmerge from 'deepmerge';

import { createHelpers } from 'vuex-map-fields';

const { createAccount } = createHelpers({
  mutationType: 'createAccount',
});

const Actions = deepmerge.all([requestAccountCreate, requestAccountValid]);
const Mutations = deepmerge.all([updateCurrentAccountObject]);

Mutations['createAccount'] = createAccount;

export const AccountQuickAddWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
