import { UPDATE_TASK_FOLLOWERS_QUERY } from 'queries/tasks/update_task_followers.js';
import { formatErrors } from  'concerns/format_errors.js';

export const updateTaskFollowers = {
  updateTaskFollowers: function(context, args) {
    var input = {
      id: args.loggable_id.toString(),
      task_follower_ids: args.task_follower_ids
    };

    return new Promise((res, rej) => {

      var apollo = args.apollo;
      apollo.mutate({
        mutation: UPDATE_TASK_FOLLOWERS_QUERY,
        variables: {
          input: input
        }
      })
      .then(response => {
        var followers = response.data.updateTaskFollowers.followers;
        context.commit('updateFollowers', followers);
        context.commit('updateLockVersion', response.data.updateTaskFollowers.lock_version);
        window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['tasks_list'] } }));
        args.componentObject.ajaxRequestSent = false;
        window.pubnubReady = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
      });
    });
  }
};
