import gql from 'graphql-tag';

export const FETCH_WORK_ORDER_QUICK_VIEW_DATA = gql `
  query showWorkOrderQuery($id: String!) {
    work_order(id: $id) {
      id
      db_id
      details
      clone_path
      edit_path
      title
      task_count_path
      state
      is_archived
      state_name
      can_clone_work_order
      is_work_order
      creator_id
      assignee_id
      not_accepted
      accept_by_datetime
      formatted_description
      accept_by_date_passed
      updated_at
      due_datetime
      work_order_follower_ids
      survey_link
      associated_total_time_logged_words
      estimated_work_time_logged_words
      is_internal
      account {
        id
        name
        profile_picture
      }
      contact {
        id
        name
        profile_picture
      }
      unarchived_sub_work_orders {
        id
        is_work_order
        title
        not_accepted
        accept_by_datetime
        accept_by_date_passed
        estimated_work_time_logged_words
        state
        state_name
        assignee_id
      }
    }
  }
`;
