import { State, Getters } from "store/data/general_configuration/state";
import * as deepmerge from 'deepmerge';

// Actions
import { fetchAccountData } from 'store/data/general_configuration/actions/fetch_account_data.js';
import { fetchContactData } from 'store/data/general_configuration/actions/fetch_contact_data.js';
import { fetchPOShippingAddressData } from 'store/data/general_configuration/actions/fetch_po_shipping_address_data.js';
// Mutations
import { updateConfigurations } from "store/data/general_configuration/mutations/update_configurations.js";
import { updateUserData } from "store/data/general_configuration/mutations/update_user_data.js";
import { updateAccountsData } from "store/data/general_configuration/mutations/update_accounts_data.js";
import { updateContactsData } from "store/data/general_configuration/mutations/update_contacts_data.js";
import { updateCurrentUser } from "store/data/general_configuration/mutations/update_current_user.js";
import { updateAjaxRequestBit } from "store/data/general_configuration/mutations/update_ajax_request_bit.js";
import { updateCurrentTimeStamp } from "store/data/general_configuration/mutations/update_current_time_stamp.js";
import { updateTopNavLinks } from "store/data/general_configuration/mutations/update_top_nav_links.js";
import { updatePOShippingAddressData } from "store/data/general_configuration/mutations/update_po_shipping_address_data.js";


import { createHelpers } from 'vuex-map-fields';

const { updateGeneralConfiguration } = createHelpers({
  mutationType: 'updateGeneralConfiguration',
});

const Actions = deepmerge.all([
  fetchAccountData,
  fetchContactData,
  fetchPOShippingAddressData
]);
const Mutations = deepmerge.all([
  updateConfigurations,
  updateAjaxRequestBit,
  updateCurrentTimeStamp,
  updateTopNavLinks,
  updateUserData,
  updateAccountsData,
  updateContactsData,
  updateCurrentUser,
  updatePOShippingAddressData
]);

Mutations['updateGeneralConfiguration'] = updateGeneralConfiguration;

export const GeneralConfiguration = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
