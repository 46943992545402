import { State, Getters } from "store/data/time_log_modal/state";
import * as deepmerge from 'deepmerge';

// Actions
import { requestCreateTimeLog } from "store/data/time_log_modal/actions/request_create_time_log";

// Mutations
import { updateNewTimeLog } from "store/data/time_log_modal/actions/update_new_time_log";
import { updateTimeLogObject } from "store/data/time_log_modal/mutations/update_time_log_object";


const Actions = deepmerge.all([
  requestCreateTimeLog,
  updateNewTimeLog
]);
const Mutations = deepmerge.all([
  updateTimeLogObject
]);

import { createHelpers } from 'vuex-map-fields';

const { createTimeLog } = createHelpers({
  getterType: 'getTimeLog',
  mutationType: 'createTimeLog'
});

Mutations['createTimeLog'] = createTimeLog;

export const TimeLogModalWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
