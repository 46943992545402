import gql from 'graphql-tag';

export const UPDATE_TIME_LOG_QUERY = gql `
  mutation($input: UpdateTimeLogInput!) {
    updateTimeLog(input: $input) {
      timeLog {
        id
        duration
        description
        lock_version
        seconds_to_cutoff
        editable
        editabled_till
        loggable_id
        loggable_type
        loggable_date
        errors {
          field_name
          errors
        }
      }
    }
  }
`;
