import { MARK_TASK_INCOMPLETE } from 'queries/tasks/mark_task_incomplete';

export const markIncomplete = {
  markIncomplete: function(context, args) {
    return new Promise((res, rej) => {
      var apollo = args.apollo;
      apollo.mutate({
        mutation: MARK_TASK_INCOMPLETE,
        variables: {
          id: args.loggable_id.toString()
        }
      })
      .then(response => {
        var task = response.data.markTaskIncomplete.task;
        if(context.state.modalOpened){
          context.commit('updateTaskObject', task);
        }
        window.dispatchEvent(new CustomEvent('updateList', { detail: { listNames: ['archived_tasks_list'] } }));
        args.componentObject.ajaxRequestSent = false;
        window.pubnubReady = false;
        res(task);
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
      });
    });
  }
};
