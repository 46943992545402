import gql from 'graphql-tag';

export const FETCH_CONTACT_TOOLTIP_DATA = gql `
  query fetch_contact($id: String!) {
    fetch_contact(id: $id) {
      id
      name
      email
      profile_picture
    }
  }
`;
