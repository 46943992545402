import { UPDATE_COMMENT_QUERY } from 'queries/comments/update_comment.js';
import { formatErrors } from  'concerns/format_errors.js';

export const requestUpdateComment = {
  requestUpdateComment: function(context, args) {
    return new Promise((res, rej) => {
      args.apollo.mutate({
        mutation: UPDATE_COMMENT_QUERY,
        variables: {
          id: args.comment_id,
          body: context.state.edit_comment_object.unformatted_body,
          urgent: context.state.edit_comment_object.urgent,
        }
      }).then((response) => {
        var comment = response.data.updateComment.comment;

        if(comment.errors.length){
          comment.errors = formatErrors(comment.errors);

          if(!$.isEmptyObject(comment.errors.base)) {
            args.showAlert('alert-error', comment.errors.base[0]);
          }
        } else {
          res(comment);
        }
        context.commit('updateEditCommentObject', comment);
        args.componentObject.ajaxRequestSent = false;

      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        rej();
      });
    });
  }
};
