import { DELETE_COMMENT_QUERY } from 'queries/comments/delete_comment.js';
import { formatErrors } from  'concerns/format_errors.js';

export const requestDeleteComment = {
  requestDeleteComment: function(context, args) {
    return new Promise((res, rej) => {
      args.apollo.mutate({
        mutation: DELETE_COMMENT_QUERY,
        variables: {
          id: args.comment_id
        }
      }).then((response) => {
        var comment = response.data.deleteComment.comment;

        if (comment.errors.length) {
          comment.errors = formatErrors(comment.errors);
          if (!$.isEmptyObject(comment.errors.base)) {
            args.showAlert('alert-error', comment.errors.base[0]);
          }
        } else {
          res(comment.id);
        }

        args.componentObject.ajaxRequestSent = false;
      }).catch((reason) => {
        args.showAlert('alert-error', reason.graphQLErrors[0].message)
        args.componentObject.ajaxRequestSent = false;
        rej();
      });
    });
  }
};
