import gql from 'graphql-tag';

export const ACCOUNT_SEARCH_QUERY = gql`
  query searchAccountsQuery($search_string: String!) {
    search_accounts(search_string: $search_string) {
      id
      name
      contact_number
      favicon_image
      email
      is_target
      qualification_criteria {
        id
        funding_raised_range
        employees_count_range
      }
    }
  }
`;
