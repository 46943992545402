import { State, Getters } from "store/data/tasks/state";
import * as deepmerge from 'deepmerge';

import { requestCreateTask } from "store/data/tasks/actions/request_create_task";

import { updateTaskObject } from "store/data/tasks/mutations/update_task_object";
import { setAttachmentId } from "store/data/tasks/mutations/set_attachment_id";

const Actions = deepmerge.all([requestCreateTask]);
const Mutations = deepmerge.all([updateTaskObject, setAttachmentId]);

import { createHelpers } from 'vuex-map-fields';

const { createTask } = createHelpers({
  getterType: 'getTask',
  mutationType: 'createTask'
});


Mutations['createTask'] = createTask;

export const TaskQuickAddWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
