import gql from 'graphql-tag';

export const CREATE_CONTACT_QUERY = gql `
  mutation($input: CreateContactInput!) {
    createContact(input: $input) {
      contact {
        id
        name
        email
        lead_status
        phone
        mobile_number
        created_at
        contracts_count
        opportunities_count
        first_touch_by_id
        first_touch_by_name
        last_activity_on
        last_activity_on_epoch
        quick_modal_object
        account_team {
          id
          name
        }
        account {
          id
          is_target
          favicon_image
          name
        }
        edit_url
        show_url
        type
        errors {
          field_name,
          errors
        }
      }
      success_message
    }
  }
`;
