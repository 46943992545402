import gql from 'graphql-tag';

export const ARCHIVE_WORK_ORDER = gql `
  mutation($input: ArchiveWorkOrderInput!) {
    archiveWorkorder(input: $input) {
      status
      message
      last_activity_hash
    }
  }
`;
