import { ARCHIVE_TASK_QUERY } from 'queries/tasks/archive_task.js';
import { formatErrors } from  'concerns/format_errors.js';

export const requestArchiveTask = {
  requestArchiveTask({ commit }, { apollo, task_id, showAlert }) {

    return new Promise((res, rej) => {
      apollo.mutate({
        mutation: ARCHIVE_TASK_QUERY,
        variables: {
          id: task_id
        }
      }).then(response => {
        var task = response.data.archiveTask.task;
        if(task.errors.length) {
          task.errors = formatErrors(task.errors);
          showAlert('alert-danger', task.errors.base[0]);
        } else {
          var success = response.data.archiveTask.success;
          showAlert('alert-success', success);
          res(task);
        }
      });
    });
  }
};
