import gql from 'graphql-tag';

export const VALIDATE_CONTACT_QUERY = gql `
  mutation($input: ValidateContactInput!) {
    validateContact(input: $input) {
      contact {
        id
        name
        email
        lead_status
        phone
        mobile_number
        quick_modal_object
        account_team {
          id
          name
        }
        account {
          id
          name
          favicon_image
          is_target
        }
        edit_url
        type
        errors {
          field_name,
          errors
        }
      }
      warnings
    }
  }
`;
