import gql from 'graphql-tag';

export const VALIDATE_ACCOUNT_QUERY = gql `
  mutation($input: ValidateAccountInput!) {
    validateAccount(input: $input) {
      account {
        id
        name
        abbreviated_name
        taxjar_customer_id
        email
        potential_rating
        estimated_spend
        is_target
        qualification_criteria {
          funding_raised_range
          special_event
          large_customer_affilates
          employees_count_range
          errors {
            field_name,
            errors
          }
        }
        errors {
          field_name,
          errors
        }
        creator {
          id
          name
          show_url
          profile_picture_url
        }
      }
    }
  }
`;
