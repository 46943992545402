import gql from 'graphql-tag';

export const DELETE_TASK_TIME_LOG = gql `
  mutation($id: ID!) {
    deleteTimeLog(input: { id: $id }) {
      timelog {
        id
        errors {
          errors
          field_name
        }
      }
      message
      success
    }
  }
`;
