var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "dateField",
    staticClass: "form-control place-holder",
    class: _vm.class_names,
    attrs: {
      type: "text",
      placeholder: "mm/dd/yyyy",
      id: _vm.id,
      disabled: _vm.disabled,
      autocomplete: "off"
    },
    on: {
      keydown: function($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
        ) {
          return null
        }
        $event.stopPropagation()
        return _vm.$emit("escapePressed")
      },
      focusout: function($event) {
        return _vm.$emit("focusOut")
      },
      focus: function($event) {
        return _vm.$emit("focusIn")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }