import * as deepmerge from 'deepmerge';

// Actions
import { searchAccounts } from 'store/data/shared/actions/search_accounts.js';

const Actions = deepmerge.all([searchAccounts]);


export const AccountSearcher = {
  actions: Actions,
  namespaced: true
};
