import gql from 'graphql-tag';

export const DELETE_SUB_WORK_ORDER = gql `
  mutation($input: DeleteSubWorkOrderInput!) {
    deleteSubWorkorder(input: $input) {
      status
      message
      last_activity_hash
    }
  }
`;
