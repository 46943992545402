import gql from 'graphql-tag';

export const CREATE_TASK_QUERY = gql `
  mutation($input: CreateTaskInput!) {
    createTask(input: $input) {
      task {
        id
        title
        description
        attachment_ids
        virtual_due_date
        has_read
        virtual_due_time
        task_follower_ids
        attachables_attachments_attributes {
          _destroy
          id
          attachment_id
          attachment_attributes {
            id
            name
            file_icon
            thumbnail_url
            document_url
            document_file_name
            file_icon_name
            errors {
              field_name
              errors
            }
          }
        }
        errors {
          field_name
          errors
        }
      }
      success
    }
  }
`;
