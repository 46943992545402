import { State, Getters } from "store/data/contacts/state";
import * as deepmerge from 'deepmerge';

import { updateCurrentContactObject } from "store/data/contacts/mutations/update_current_contact_object";
import { fetchContact } from 'store/data/contacts/actions/fetch_contact.js';
import { createQuickContact } from "store/data/contacts/actions/create_quick_contact";
import { fetchAccount } from 'store/data/contacts/actions/fetch_account.js';
import { validateQuickContact } from "store/data/contacts/actions/validate_quick_contact";

const Actions = deepmerge.all([createQuickContact, validateQuickContact, fetchAccount, fetchContact]);
const Mutations = deepmerge.all([updateCurrentContactObject]);

import { createHelpers } from 'vuex-map-fields';

const { createContact } = createHelpers({
  getterType: 'getContact',
  mutationType: 'createContact'
});


Mutations['createContact'] = createContact;

export const ContactQuickAddWidget = {
  state: State,
  actions: Actions,
  mutations: Mutations,
  getters: Getters,
  namespaced: true
};
